/* theme/content-block */
.wrapper {
  &:global(.bg-color-summer-green) {
    @apply bg-summer-green-23;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-green) {
    @apply bg-green-25;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-white) {
    @apply bg-white;
    @apply py-10 md:py-16;
  }

  & :global(> .inner-wrapper) {
    @apply box-content px-4 mx-auto md:px-8 max-w-8xl;
  }
}

.layout {
  @apply grid grid-cols-1 gap-10 lg:grid-cols-3;

  & > div {
    @apply p-0 m-0;
  }
}

.layout-half {
  @apply lg:grid-cols-2;
}

.layout-40-60,
.layout-60-40 {
  @apply lg:grid-cols-3;
}

.layout-40-60 {
  & div:nth-child(2) {
    @apply lg:col-span-2;
  }
}

.layout-60-40 {
  & div:nth-child(1) {
    @apply lg:col-span-2;
  }
}
